import React, { useEffect, lazy, Suspense } from "react";
import { GlobalDebug } from "./removeConsole";
import "./App.css";
import "firebase/compat/messaging";
import {
  PrivateRoutes,
  PrivateRoutesRefresh,
  IfUser,
} from "./routes/privateRoutes";

import { connect } from "react-redux";
import { alertActions } from "./redux/actions/alertAction";
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  useNavigate,
} from "react-router-dom";
import { createBrowserHistory } from "history";
import socket from "./helpers/socket";
import firebase from "./firebase";
import SocketState from "./socketContext/socketState";
import Loading from "./components/loader/loader";
//console.log = function () {};
//const socket = lazy (() => import("./helpers/socket"));
const Login = lazy(() => import("./components/login/login.js"));
const ResetPassword = lazy(() =>
  import("./components/reset-password/resetPassword.js")
);
const ForgotPassword = lazy(() =>
  import("./components/reset-password/forgotPassword")
);
const MobileInstall = lazy(() =>
  import("./components/redirect/mobile-install")
);
const Home = lazy(() => import("./components/home/home.js"));
const HomeLanding = lazy(() => import("./components/home/homelanding"));
const Signup = lazy(() => import("./components/signup/signup.js"));
const MyNetwork = lazy(() => import("./components/mynetwork/mynetwork.js"));
const Connect = lazy(() => import("./components/connect/connect"));
const Dating = lazy(() => import("./components/Dating/dating"));
const DatingNew = lazy(() => import("./components/Dating/datingNew"));
const MyMatches = lazy(() => import("./components/Dating/myMatches/myMatches"));
const MarketPlace = lazy(() =>
  import("./components/marketplace/market/marketplace")
);
const HomeMarketPlace = lazy(() =>
  import("./components/marketplace/market/marketplacehome")
);
const MarketPlaceCategory = lazy(() =>
  import("./components/marketplace/category/marketCategory")
);
const TiresRims = lazy(() =>
  import("./components/marketplace/classifieds/tires")
);
const TiresMobile = lazy(() =>
  import("./components/marketplace/classifieds/tiresmobile")
);
const Tires = lazy(() =>
  import("./components/marketplace/classifieds/shoptires")
);
const Classifieds = lazy(() =>
  import("./components/marketplace/classifieds/classifieds")
);
const Subscriptions = lazy(() =>
  import("./components/marketplace/classifieds/subscriptions")
);
const ClassifiedStore = lazy(() =>
  import("./components/marketplace/classifieds/classifiedStore")
);
const MyStore = lazy(() =>
  import("./components/marketplace/classifieds/myStore")
);
const Rentals = lazy(() => import("./components/marketplace/rentals/rentals"));
const CreateClassifieds = lazy(() =>
  import("./components/marketplace/classifieds/form/createClassifieds")
);
const EditClassifieds = lazy(() =>
  import("./components/marketplace/classifieds/form/editClassifieds")
);
const Jobs = lazy(() => import("./components/jobs/jobs"));
const MyJob = lazy(() => import("./components/jobs/myJob/myJob"));
const Recommended = lazy(() =>
  import("./components/jobs/recommended/recommended")
);
const Events = lazy(() => import("./components/events/events.js"));
const CreateEvent = lazy(() => import("./components/events/form/createEvent"));
const UserProfile = lazy(() =>
  import("./components/userProfile/userProfile.js")
);
const MyProfile = lazy(() => import("./components/myprofile/myProfile.js"));
const Services = lazy(() => import("./components/services/services.js"));
const MyService = lazy(() =>
  import("./components/services/myService/myService")
);
const CompleteService = lazy(() =>
  import("./components/services/complete/complete")
);
const Responded = lazy(() =>
  import("./components/services/responded/responded")
);
const JobDetails = lazy(() => import("./components/jobDetails/jobDetails.js"));
const Compare = lazy(() => import("./components/compare/compare.js"));
const Resume = lazy(() => import("./components/resume/resumee"));
const Article = lazy(() => import("./components/article/article"));
const AddArticle = lazy(() => import("./components/article/AddArticle"));
const EditArticle = lazy(() => import("./components/article/EditArticle"));
const Chat = lazy(() => import("./components/chat/chat.js"));
const RealState = lazy(() => import("./components/realState/realState"));
const Pages = lazy(() => import("./components/pages/pages"));
const CreateProperty = lazy(() =>
  import("./components/realState/form/createProperty")
);
const CreatePage = lazy(() =>
  import("./components/pages/createPage/createPage")
);
const EditPage = lazy(() => import("./components/pages/editPage/editPage"));
const MyPages = lazy(() => import("./components/pages/myPages/myPages"));
const Groups = lazy(() => import("./components/groups/groups"));
const GroupChat = lazy(() => import("./components/groupChat/groupChat"));
const EditGroup = lazy(() => import("./components/groups/editGroup/editGroup"));
const CreateGroup = lazy(() => import("./components/groups/form/createGroup"));
const Forms = lazy(() => import("./components/form/form"));
const ArticleDetails = lazy(() =>
  import("./components/article/articleDetails")
);
const ProductDetails = lazy(() =>
  import("./components/marketplace/productDetails/productDetails")
);
const SubscriptionDetails = lazy(() =>
  import("./components/marketplace/productDetails/subscriptionDetails")
);
const Cart = lazy(() => import("./components/marketplace/cart/cart"));
const CartSubscription = lazy(() =>
  import("./components/marketplace/cart/cartSubscription")
);
const AdCart = lazy(() => import("./components/ads/cart/adcart"));
const Wishlist = lazy(() =>
  import("./components/marketplace/wishlist/wishList")
);
const Settings = lazy(() => import("./components/settings/settings"));
const RealEstateDetails = lazy(() =>
  import("./components/realEstateDetails/realEstateDetails")
);
// import Business from "./components/business/business";
const PageDetails = lazy(() =>
  import("./components/pages/pageDetails/pageDetails")
);
const PageFeed = lazy(() => import("./components/pages/pageDetails/pageFeed"));
// import BusinessDetails from "./components/businessDetails/businessDetails";
const ServiceDetails = lazy(() =>
  import("./components/service-details/service")
);
const UserChat = lazy(() => import("./components/userAllChat/userChat"));
// import socket from "./helpers/socket";
const Response = lazy(() => import("./components/respondedService/response"));
const Complete = lazy(() => import("./components/completeService/complete"));
const NotFound = lazy(() => import("./components/notFound/notfound"));
const EditResume = lazy(() => import("./components/resume/editResume"));
const UserGroup = lazy(() => import("./components/groups/userGroup"));
const GroupFeed = lazy(() => import("./components/groups/groupFeed"));
const SharePost = lazy(() => import("./components/sharePost/sharePost"));
const GlobalSharePost = lazy(() =>
  import("./components/globalSharePost/sharePost")
);
const CreateJob = lazy(() => import("./components/jobs/form/createJob"));
const EditJob = lazy(() => import("./components/jobs/form/editJob"));
const ServiceForm = lazy(() =>
  import("./components/services/form/serviceForm")
);
const EditService = lazy(() =>
  import("./components/services/form/editService")
);
const EmailOtp = lazy(() => import("./components/auth/emailOtp/emailOtp"));
const PhoneOtp = lazy(() => import("./components/auth/phoneOtp"));
const Request = lazy(() => import("./components/Dating/request/request"));
const Chatting = lazy(() => import("./components/Dating/chatting/chatting"));
const DatingProfile = lazy(() =>
  import("./components/Dating/datingProfile/datingProfile")
);
const Checkout = lazy(() =>
  import("./components/marketplace/checkout/checkout")
);
const CheckoutSubscription = lazy(() =>
  import("./components/marketplace/checkout/checkoutSubscription")
);
const AdCheckout = lazy(() => import("./components/ads/checkout/adcheckout"));
const Orders = lazy(() => import("./components/marketplace/orders/orders"));
const AdOrders = lazy(() => import("./components/ads/orders/orders"));
const Payment = lazy(() => import("./components/marketplace/payment/payment"));
const PaymentSubscription = lazy(() =>
  import("./components/marketplace/payment/paymentSubscription")
);
const AdPayment = lazy(() => import("./components/ads/payment/adpayment"));
const GlobalFeed = lazy(() => import("./components/globalFeed/globalFeed"));
const Landing = lazy(() => import("./components/landing/landing.js"));
const Search = lazy(() => import("./components/jobs/eSearch"));
const JobCategory = lazy(() => import("./components/jobs/categories"));
const Terms = lazy(() => import("./components/about/terms"));
const Contact = lazy(() => import("./components/about/contact"));
const Privacy = lazy(() => import("./components/about/privacy"));
const About = lazy(() => import("./components/about/about"));
const Tips = lazy(() => import("./components/about/tips"));
const Fees = lazy(() => import("./components/about/fees"));
const DeleteAccount = lazy(() => import("./components/about/deleteAccount"));

function App(props) {
  const history = createBrowserHistory();
  const navigate = useNavigate();

  // constructor(props) {
  //     super(props);
  //     history.listen((location, action) => {
  //         // clear alert on location change
  //         props.clearAlerts();
  //     });
  // }
  //  const socket = socket;
  // const socketContext = createContext();
  // const [userList, setList] = useState([]);
  // let arr =[];
  useEffect(() => {
    history.listen((location, action) => {
      // clear alert on location change
      props.clearAlerts();
    });

    logOutDevice();
    let messaging = null;
    if (firebase.messaging.isSupported()) {
      const messaging = firebase.messaging();
      messaging.getToken().then((token) => {
        console.log(token, "<<<<<<<fcm_token");
        localStorage.setItem("fcm_token", token);
      });

      /*messaging
        .requestPermission()
        .then(() => {
          return messaging.getToken();
        })
        .then((token) => {
          c
          //console.log(token, "<<<<<<<fcm_token");
        })
        .catch((error) => {
          //console.log("permission for firebase failed");
          console.log(error, "error");
        });*/
    }
  }, []);

  // Logout socket
  const logOutDevice = () => {
    let user = JSON.parse(localStorage.getItem("user"));
    if (user && user.data && user.data.success) {
      let thisDeviceId = localStorage.getItem("deviceId");
      socket.on("deviceId" + thisDeviceId, (deviceId) => {
        console.log(deviceId);
        if (thisDeviceId === deviceId) {
          localStorage.clear();
          window.location.reload(() => {
            navigate("/login");
          });
        }
      });
    }
  };

  return (
    <>
      <div
      // style={{margin:'0 auto', maxWidth: '1920px'}}
      >
        <SocketState>
          {/*<BrowserRouter history={history}>*/}
          <Suspense fallback={<Loading />}>
            <Routes>
              <Route
                path="/password-reset/:id/:token"
                exact
                element={<ResetPassword />}
                //render={(props) => <ResetPassword {...props} />}
              />
              <Route
                path="/forgot-password"
                exact
                element={<ForgotPassword />}
                //render={(props) => <ForgotPassword {...props} />}
              />
              {/*<Route element={<IfUser />}>*/}
              <Route
                path="/"
                exact
                element={<HomeMarketPlace />}
                //element={<HomeLanding />}
                //render={(props) => <Landing {...props} />}
              />
              <Route
                path="/feeds"
                exact
                element={<HomeLanding />}
                //render={(props) => <Landing {...props} />}
              />
              <Route
                path="/trends"
                exact
                element={<HomeLanding />}
                //render={(props) => <Landing {...props} />}
              />
              <Route
                path="/landing"
                exact
                element={<Landing />}
                //render={(props) => <Landing {...props} />}
              />
              <Route
                path="/login"
                exact
                element={<Login />}
                //render={(props) => <Login {...props} />}
              />
              <Route
                path="/signup"
                exact
                element={<Signup />}
                //render={(props) => <Signup {...props} />}
              />
              {/*</Route>*/}
              {/*--------------Dating-----------*/}
              {/*------------MarketPlacee----------*/}
              <Route path="/marketplace" exact element={<MarketPlace />} />
              <Route
                path="/marketplace/products/:query"
                exact
                element={<MarketPlace />}
              />
              <Route
                path="/marketplace/location/:location"
                exact
                element={<MarketPlace />}
              />
              <Route
                path="/marketplace/business/:business"
                exact
                element={<MarketPlace />}
              />

              <Route path="/tires-rims" exact element={<TiresRims />} />
              <Route path="/tires" exact element={<Tires />} />
              <Route path="/tires-mobile" exact element={<TiresMobile />} />
              <Route path="/classifieds" exact element={<Classifieds />} />
              <Route path="/subscriptions" exact element={<Subscriptions />} />
              <Route
                path="/cart-subscription"
                exact
                element={<CartSubscription />}
              />
              <Route
                path="/checkout-subscription"
                exact
                element={<CheckoutSubscription />}
              />
              <Route
                path="/payment-subscription"
                exact
                element={<PaymentSubscription />}
              />
              <Route
                path="/classifieds-store/:sellerId"
                exact
                element={<ClassifiedStore />}
              />
              <Route
                path="/product-details/:id"
                exact
                element={<ProductDetails />}
              />
              <Route
                path="/subscription-details/:id"
                exact
                element={<SubscriptionDetails />}
              />
              <Route path="/my-store" exact element={<MyStore />} />
              <Route element={<PrivateRoutes />}>
                <Route path="/home" exact element={<Home />} />
                <Route path="/my-network" exact element={<MyNetwork />} />
                <Route path="/connect" exact element={<Connect />} />
                <Route path="/dating" exact element={<Dating />} />
                <Route path="/dating/new" exact element={<DatingNew />} />
                <Route
                  path="/dating/my-matches"
                  exact
                  element={<MyMatches />}
                />
                <Route path="/dating/request" exact element={<Request />} />
                <Route
                  path="/dating/chatting/:id"
                  exact
                  element={<Chatting />}
                />
                <Route
                  path="/dating/profile/:id"
                  exact
                  element={<DatingProfile />}
                />
                <Route
                  path="/user-chat/:receiverId"
                  exact
                  element={<UserChat />}
                />
                <Route path="/rentals" exact element={<Rentals />} />
                <Route
                  path="/category/:market/:categoryId/:categoryType"
                  exact
                  element={<MarketPlaceCategory />}
                />
                <Route path="/cart" exact element={<Cart />} />
                <Route path="/adcart" exact element={<AdCart />} />
                <Route path="/wishlist" exact element={<Wishlist />} />
                <Route path="/orders" exact element={<Orders />} />
                <Route path="/adorders" exact element={<AdOrders />} />
                <Route path="/payment" exact element={<Payment />} />
                <Route path="/adpayment/:id" exact element={<AdPayment />} />
                {/* Merge */}
                <Route
                  path="/create-classifieds"
                  exact
                  element={<CreateClassifieds />}
                />
                <Route
                  path="/createProperty"
                  exact
                  element={<CreateProperty />}
                />
                <Route
                  path="/edit-classifieds/:id"
                  exact
                  element={<EditClassifieds />}
                />
                {/* merge */}
                <Route path="/complete/:id" exact element={<Complete />} />
                {/* Job Module */}
                <Route path="/jobs/my-jobs" exact={true} element={<MyJob />} />
                <Route path="/job/create-job" exact element={<CreateJob />} />
                <Route
                  path="/job/edit-job/:jobId"
                  exact
                  element={<EditJob />}
                />
                <Route
                  path="/jobs/recommended-job"
                  exact
                  element={<Recommended />}
                />
                <Route path="/responded/:id" exact element={<Response />} />
                <Route
                  path="/service-details/:id"
                  exact
                  element={<ServiceDetails />}
                />
                <Route path="/events" exact element={<Events />} />
                <Route
                  path="/events/createEvent"
                  exact
                  element={<CreateEvent />}
                />
                <Route path="/group-feed/:id" exact element={<GroupFeed />} />
                <Route
                  path="/user-profile/:profileId"
                  exact
                  element={<UserProfile />}
                />
                <Route path="/my-profile" exact element={<MyProfile />} />
                <Route path="/services" exact element={<Services />} />
                <Route
                  path="/services/complete"
                  exact
                  element={<CompleteService />}
                />
                <Route
                  path="/services/responded"
                  exact
                  element={<Responded />}
                />
                <Route
                  path="/services/my-services"
                  exact
                  element={<MyService />}
                />
                <Route path="/services/form" exact element={<ServiceForm />} />
                <Route
                  path="/services/edit-service/:id"
                  exact
                  element={<EditService />}
                />
                <Route path="/compare" exact element={<Compare />} />
                <Route path="/resume" exact element={<Resume />} />
                <Route path="/editResume/:id" exact element={<EditResume />} />
                <Route
                  path="/realEstateDetails/:id"
                  exact
                  element={<RealEstateDetails />}
                />
                <Route path="/add-article" element={<AddArticle />} />
                <Route
                  path="/edit-article/:id"
                  exact
                  element={<EditArticle />}
                />
                <Route path="/settings/:id" exact element={<Settings />} />
                <Route path="/chat/:id" element={<Chat />} />
                <Route path="/form/:id" element={<Forms />} />
                <Route path="/pages/create-page" element={<CreatePage />} />
                <Route
                  path="/pages/edit-page/:id"
                  exact
                  element={<EditPage />}
                />
                <Route path="/pages/my-pages" element={<MyPages />} />
                <Route path="/realState" element={<RealState />} />
                <Route path="/groupChat/:groupId" element={<GroupChat />} />
                <Route path="/editGroup/:groupId" element={<EditGroup />} />
                <Route path="/create-group" element={<CreateGroup />} />
                <Route path="/my-groups" element={<UserGroup />} />
                <Route path="/global-post/:id" element={<GlobalSharePost />} />
                <Route path="/edit-resume/:id" element={<EditResume />} />
                <Route path="/checkout" element={<Checkout />} />
                <Route path="/adcheckout" element={<AdCheckout />} />
                <Route path="/search" exact={true} element={<Search />} />
                <Route path="/groups" element={<Groups />} />
              </Route>
              <Route path="/mobile" exact element={<MobileInstall />} />
              <Route
                path="/job-details/:jobId"
                exact
                element={<JobDetails />}
              />
              <Route path="/post/:id" element={<SharePost />} />
              <Route path="/pages" exact element={<Pages />} />
              <Route path="/pages/company/:company" exact element={<Pages />} />
              <Route path="/pages/query/:query" exact element={<Pages />} />
              <Route
                path="/pages/category/:category"
                exact
                element={<Pages />}
              />

              <Route
                path="/pages/location/:location"
                exact
                element={<Pages />}
              />

              <Route
                path="/page-details/:pageId"
                exact
                element={<PageDetails />}
              />
              <Route path="/page-feed/:id" exact element={<PageFeed />} />
              <Route path="/jobs" exact={true} element={<Jobs />} />
              <Route
                path="/jobs/query/:query"
                exact={true}
                element={<Jobs />}
              />
              <Route
                path="/jobs/location/:location"
                exact={true}
                element={<Jobs />}
              />
              <Route
                path="/jobs/company/:company"
                exact={true}
                element={<Jobs />}
              />
              {/* Article */}
              <Route path="/article/:id" exact element={<Article />} />
              <Route path="/articles" exact element={<Article />} />
              <Route
                path="/articles/query/:query"
                exact
                element={<Article />}
              />
              <Route path="/articles/user/:user" exact element={<Article />} />
              <Route
                path="/articles/category/:category"
                exact
                element={<Article />}
              />

              <Route
                path="/article-details/:articleId"
                element={<ArticleDetails />}
              />
              <Route
                path="/jobs/category/:category"
                exact={true}
                element={<JobCategory />}
              />
              <Route
                path="/email-verification/:userId"
                element={<EmailOtp />}
              />
              <Route
                path="/phone-verification/:userId"
                element={<PhoneOtp />}
              />
              <Route path="/privacy" element={<Privacy />} />
              <Route path="/terms" element={<Terms />} />
              <Route path="/about" element={<About />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/contact-us" element={<Navigate to="/contact" />} />
              <Route path="/tips" element={<Tips />} />
              <Route path="/fees" element={<Fees />} />
              <Route path="/delete-account" element={<DeleteAccount />} />
              <Route path="*" element={<Navigate to="/" />} />
              {/* <Route path="/header" exact render ={props => <Header {...props}/>}></Route> */}
            </Routes>
          </Suspense>
          {/*</BrowserRouter>*/}
        </SocketState>
      </div>
    </>
  );
}

function mapState(state) {
  const { alert } = state;
  return { alert };
}

const actionCreators = {
  clearAlerts: alertActions.clear,
};

export default connect(mapState, actionCreators)(App);
