import { combineReducers } from 'redux';

import { authentication } from './authReducers';
import { register } from './registerReducer';
import { alert } from './alertReducer';
import { LOADING_TRUE,LOADING_FALSE } from '../constant/actionTypes'


const rootReducer = combineReducers({
  authentication,
  alert,
  register
});

export default rootReducer;