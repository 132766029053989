import React, { useState, useEffect } from "react";
import socket from "../helpers/socket";
import SocketContext from "./socketContext";

export default function SocketState(props) {
  const [userList, setList] = useState([]);
  let arr = [];
  useEffect(() => {
    setTimeout(() => {
      configSocket();
      getUser();
    }, 1000);
  }, []);

  const getUser = async () => {
    const user = JSON.parse(localStorage.getItem("user"));

    if (user && user.data && user.data.success) {
      socket.on("getUsers", (data) => {
        //console.log("user data ===>",data)
        data.forEach((element) => {
          if (element.userId != user.data.data.id) {
            arr.push(element);
          }
        });
        let userData = arr.filter((item, index, objects) => {
          if (index === 0) {
            return item;
          } else if (item.userId !== objects[index - 1].userId) {
            return item;
          }
        });
        // //console.log(userData,"<===data")
        setList(userData);
      });
    }
  };
  const configSocket = async () => {
    const user = JSON.parse(localStorage.getItem("user"));
    if (!user) {
      return;
    }
    if (user.data.success === false) {
      return;
    }
    if (user && user.data && user.data.success) {
      let users = {
        firstname: user.data.data.firstname,
        lastname: user.data.data.lastname,
        userId: user.data.data.id,
        email: user.data.data.email,
        image:
          (Array.isArray(user.data.data.image) &&
            user.data.data.image.length) == 0
            ? ""
            : user.data.data.image[0].url,
        username: user.data.data.username,
        createdAt: Date.now(),
      };
      socket.emit("addUser", users);
      getUser();
      socket.emit("IamOnline", { userId: user.data.data.id });
    } else {
    }
  };

  return (
    <SocketContext.Provider value={userList}>
      {props.children}
    </SocketContext.Provider>
  );
}
