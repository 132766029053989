import { io } from "socket.io-client";
import api from "../routes/api";

const user = JSON.parse(localStorage.getItem("user"));

if (!user || user.data === null) {
}
let socket;

if (user && user.data && user.data.success) {
  const token = user.data.token;
  console.log(api.ngrok, "<<<socket check");
  socket = io(api.ngrok, {
    query: { token },
  });
} else {
}

export default socket;
