import {REGISTER_FAILURE,REGISTER_SUCCESS,REGISTER_REQUEST} from '../constant/actionTypes';
export function register(state = {}, action) {
    switch (action.type) {
      case REGISTER_REQUEST:
      return { registering: true };
      case REGISTER_SUCCESS:
      return {};
      case REGISTER_FAILURE:
      return {};
      default:
      return state
    }
  }