import firebase from "firebase/compat/app";
import "firebase/compat/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyDGQDyTgxRLLkEqVwggkDRdh7OGNNzxXUw",
  authDomain: "fonolive-1-fonolive.firebaseapp.com",
  projectId: "fonolive-1-fonolive",
  storageBucket: "fonolive-1-fonolive.appspot.com",
  messagingSenderId: "132318327758",
  appId: "1:132318327758:web:f637c6cdb2523719f5f19f",
  measurementId: "G-ZE4PMXV7J2",
};

firebase.initializeApp(firebaseConfig);
export default firebase;
