import React from 'react';
import './loading.css';

const Loading = () => {

    // document.getElementsByTagName('BODY').classList.add('bodyOverflow')
    
    return (
        <div className='loader-full-width'>
            <div className="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
        </div>)
}

export default Loading;