import React from "react";
import { Outlet, Navigate } from "react-router-dom";

const userLogged = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  return user && user.data && user.data.success ? true : false;
};

export const PrivateRoutes = () => {
  return userLogged() ? <Outlet /> : <Navigate to="/login" />;
};

export const PrivateRoutesRefresh = () => {
  return userLogged() ? <Outlet /> : <Navigate to="/login" />;
};

export const IfUser = () => {
  return userLogged() ? <Navigate to="/home" /> : <Outlet />;
};
